import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  endOfDay,
  endOfWeek,
  format,
  getISOWeek,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfWeek,
} from "date-fns";

import { plantaApi } from "../../api/fetch";
import type {
  Budget,
  BudgetArgs,
  UseGetBudgetReturn,
  PeriodCalculation,
} from "./types";
import {
  periodBudget$,
  periodBudgetTotalWeeksDates$,
  periodBudgetPerfectWeeksDates$,
} from "../period";
import { ORG_NODE_BY_ID, orgNodes$ } from "../org/state";
import { getCostCentresFromOrgNode } from "../org/transforms";
import { ClientBusinessNode } from "../org/types";
import { budget$, budgetData$ } from "../plan/state";
import { weekStartsOn$ } from "../dates/state";

export const useGetBudget = ({
  unitType,
  publicUnitId,
  orgId,
  startDate,
  endDate,
  keepPreviousData,
}: BudgetArgs): UseGetBudgetReturn => {
  const selectedOrg = ORG_NODE_BY_ID.get(orgId) as ClientBusinessNode;
  const orgNodes = orgNodes$.value;

  const startOfSelectedWeek = format(
    startOfWeek(new Date(startDate), { weekStartsOn: weekStartsOn$.value }),
    "yyyy-MM-dd",
  );

  const { data, refetch, isLoading } = useQuery<ReadonlyArray<Budget>>({
    queryKey: [`${unitType}-${publicUnitId}-${startOfSelectedWeek}-${endDate}`],
    queryFn: async ({ signal }) => {
      if (keepPreviousData === true) {
        return [];
      }
      const budget = await plantaApi.jsonInJsonOut<ReadonlyArray<Budget>>(
        `/budgets/${unitType}/${publicUnitId}?startDate=${startOfSelectedWeek}&endDate=${endDate}`,
        { method: "GET", signal },
      );
      return budget;
    },
    enabled: true,
  });

  useEffect(() => {
    if (data) {
      budgetData$.value = data;
    }
  }, [data]);

  if (data === undefined) {
    return {
      data: [],
      rawData: [],
      isLoading: false,
      refetch,
    };
  }

  //Get all CCs in the selected org
  const costCentres = getCostCentresFromOrgNode(orgNodes, selectedOrg);

  /*
  The CostCentre Ids we get from budgeting and extIds for CostCentres are formatted like this: CR71013340
  Our org endpoint returns it in this format: "CC4030",
  so we have to extract the last 4 digits from the budgeting CCs and compare it to the org CCs.
   */
  const { filteredData } = data.reduce(
    (acc, item) => {
      if (
        item.costCentreCode.length > 4 &&
        costCentres.has(`CC${item.costCentreCode.substring(6, 10)}`)
      ) {
        acc.filteredData.push(item);
      }

      return acc;
    },
    { filteredData: [] as Budget[] },
  );
  const { min, max } = calculatePeriodHours(filteredData, endDate, startDate);

  periodBudget$.value = { min, max };

  periodBudgetTotalWeeksDates$.value = [new Date(startDate), new Date(endDate)];

  /**
   * Aggregates budget hours by week number.
   * If multiple budget entries exist for the same week (e.g., from different cost centers),
   * their hours are summed up into a single entry for that week.
   */
  const processedData = Array.from(
    filteredData.reduce((acc, budget) => {
      const weekNumber = getISOWeek(new Date(budget.date));
      acc.set(weekNumber, (acc.get(weekNumber) ?? 0) + budget.hours);
      return acc;
    }, new Map<number, number>()),
    ([weekNumber, hours]) => ({ weekNumber, hours }),
  );

  budget$.value = processedData;

  return {
    data: processedData,
    rawData: filteredData,
    isLoading,
    refetch,
  };
};

function calculatePeriodHours(
  data: Budget[],
  endDate: string,
  startDate: string,
): PeriodCalculation {
  let minVal = 0;
  let maxVal = 0;
  const eodPeriodEnd = endOfDay(new Date(endDate));
  const sodPeriodStart = startOfDay(new Date(startDate));

  for (const item of data) {
    const weekEnd = endOfWeek(new Date(item.date), {
      weekStartsOn: weekStartsOn$.value,
    });
    if (
      (isBefore(weekEnd, eodPeriodEnd) || isEqual(weekEnd, eodPeriodEnd)) &&
      (isAfter(new Date(item.date), new Date(sodPeriodStart)) ||
        isEqual(new Date(item.date), new Date(sodPeriodStart)))
    ) {
      minVal += item.hours * 60;
      periodBudgetPerfectWeeksDates$.value = [
        new Date(item.date),
        new Date(weekEnd),
      ];
    }
    maxVal += item.hours * 60;
  }
  return { min: minVal, max: maxVal };
}
