import { FC } from "react";
import { useTranslation } from "react-i18next";

import SSRIcon from "@ingka/ssr-icon";
import MoneyBill from "@ingka/ssr-icon/paths/money-bills";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";

import { css } from "../__generated-styled-system/css";
import {
  periodBudget$,
  periodBudgetTotalWeeksDates$,
  periodBudgetPerfectWeeksDates$,
} from "../features/period";
import { fmtHhMm } from "../features/dates/transforms";
import { getWeeksInRange } from "../features/dates/transforms";
import { weekStartsOn$ } from "../features/dates/state";

const wrapperStyles = css({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  gridRowGap: "space100",
});

const periodPlanTextStyles = css({
  marginLeft: "space50!",
});

const textStyles = css({ gridColumn: "-1 / 1" });

export const PlanBudget: FC = () => {
  const { t } = useTranslation();
  const { min, max } = periodBudget$.value;
  const [startDateForTotalWeeks, endDateForTotalWeeks] =
    periodBudgetTotalWeeksDates$.value;
  const [startDateForPerfectWeeks, endDateForPerfectWeeks] =
    periodBudgetPerfectWeeksDates$.value;

  const weekRangeForTotalWeeks = getWeeksInRange(
    startDateForTotalWeeks,
    endDateForTotalWeeks,
    weekStartsOn$.value,
  );

  const weekRangeForPerfectWeeks = getWeeksInRange(
    startDateForPerfectWeeks,
    endDateForPerfectWeeks,
    weekStartsOn$.value,
  );

  return (
    <div className={wrapperStyles}>
      <Text className={textStyles} tagName="p" bodySize="s">
        {t("plan.periodBudget")}
      </Text>
      <SSRIcon paths={MoneyBill} />
      <Text className={periodPlanTextStyles} tagName="span" headingSize="xs">
        <Tooltip
          className="budget-tableHeaderCell"
          describedById="tooltip-example"
          position="bottom"
          quickPreview
          tooltipText={
            min === max ? (
              <span style={{ fontWeight: "normal" }}>
                {t("plan.totalBudgetForWeek")} {weekRangeForTotalWeeks[0]}-
                {weekRangeForTotalWeeks[weekRangeForTotalWeeks.length - 1]}
              </span>
            ) : (
              <>
                <span>{fmtHhMm(min)}</span>
                <span style={{ fontWeight: "normal" }}>
                  : {t("plan.budgetForWeek")} {weekRangeForPerfectWeeks[0]}-
                  {
                    weekRangeForPerfectWeeks[
                      weekRangeForPerfectWeeks.length - 1
                    ]
                  }
                </span>
                <br />
                <span>{fmtHhMm(max)}</span>
                <span style={{ fontWeight: "normal" }}>
                  : {t("plan.budgetForWeek")} {weekRangeForTotalWeeks[0]}-
                  {weekRangeForTotalWeeks[weekRangeForTotalWeeks.length - 1]}
                </span>
              </>
            )
          }
          boundaryContainerId="tool-id"
        >
          <div>
            {min === max
              ? `${fmtHhMm(max)}`
              : `${fmtHhMm(min)} - ${fmtHhMm(max)}`}
          </div>
        </Tooltip>
      </Text>
    </div>
  );
};
