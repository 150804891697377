import { signal, effect, computed } from "@preact-signals/safe-react";
import { format } from "date-fns";

import { getFiscalYear } from "../../utils";
import { decodePlanningPeriod, encodePlanningPeriod } from "./transforms";
import {
  decodeShareableSetting,
  encodeShareableSetting,
} from "../../utils/shareableSetting";
import { HeadCount, PlanViewData } from "../../types/api";
import { getDurationFromHeadCountFormatted } from "../plan/transforms";
import { PeriodCalculation } from "../budget/types";

const KEY = "period";

type PlanData = PlanViewData["data"];

// TODO: clean up these two signals and the use of them, only one should be enough.
// NOTE: updated when view is Week or Month
export const periodPlan$ = signal<PlanData>([]);

// NOTE: updated when view is Period
export const totalPeriodPlan$ = signal<HeadCount[]>([]);

export const modifiedPlans$ = signal<Map<string, number[]>>(new Map());

export const periodBudget$ = signal<PeriodCalculation>({ min: 0, max: 0 });

export const periodBudgetTotalWeeksDates$ = signal<[Date, Date]>([
  new Date(),
  new Date(),
]);

export const periodBudgetPerfectWeeksDates$ = signal<[Date, Date]>([
  new Date(),
  new Date(),
]);

export const period$ = signal(
  decodeShareableSetting(KEY, decodePlanningPeriod) ?? getFiscalYear(),
);

effect(() => {
  const value = period$.value;
  if (value !== undefined) {
    encodeShareableSetting(KEY, value, encodePlanningPeriod);
  }
});

effect(() => {
  //So OPP can also get the period from our client package
  localStorage.setItem(
    "period",
    [
      format(period$.value[0], "yyyy-MM-dd"),
      format(period$.value[1], "yyyy-MM-dd"),
    ].join(","),
  );
});

//Stores heardcount based on periodPlan (based on if view is Week or Month).
export const weekMonthPlanTotal$ = computed<string>(() => {
  const periodPlan = periodPlan$.value;

  return getDurationFromHeadCountFormatted(
    periodPlan.flatMap((h) => h.dayQuarters),
  );
});

//Stores total Period headcount (Based on period selection in header)
export const periodPlanTotal$ = computed<string>(() => {
  const periodHeadCount = totalPeriodPlan$.value;

  return getDurationFromHeadCountFormatted(
    periodHeadCount.flatMap((h) => h.dayQuarters),
  );
});
